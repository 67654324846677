body {
  font-family: 'Arial', sans-serif;
}
/* body {
  background-color: #f0f2f5;
}

.container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 1200px;
  padding: 2rem;
  background-color: #2d3135;
  color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.title {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #ffc107;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.filterTitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.filterDropdown {
  width: 50%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
  color: #1a1a1a;
  border-radius: 5px;
}

.exportButton {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 16px 2px;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
}

.exportButton:hover {
  background-color: #218838;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.gamesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.gameCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
  margin: 1rem;
  color: #1a1a1a;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

@media (min-width: 576px) {
  .gameCard {
    width: 48%;
  }
}

@media (min-width: 992px) {
  .gameCard {
    width: 22%;
  }
}

.gameDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.gameImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.gameName {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.gameProvider {
  font-size: 0.9rem;
}



.loadingMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
  font-weight: bold;
} */

.goToTopButton {
  position: fixed;
  right: 20px;
  bottom: 85px;
  background-color: #4a4e69;
  border: 2px solid #4a4e69;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: 0.3s;
}

.goToTopButton:hover {
  background-color: #373a47;
  border-color: #373a47;
}

.goToTopIcon {
  color: #fff;
  font-size: 20px;
}

/* GameTable.module.css */

.tableContainer {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: #1a1a1a;
  color: #f1c40f;
  border: 2px solid #f1c40f;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tableContainer thead {
  font-size: 1.2em;
  text-transform: uppercase;
}

.tableContainer thead tr {
  background-color: #f1c40f;
  color: #1a1a1a;
}

.tableContainer tbody tr {
  border-top: 1px solid #f1c40f;
}

.tableContainer tbody tr:nth-child(even) {
  background-color: rgba(241, 196, 15, 0.1);
}

.tableContainer td,
.tableContainer th {
  padding: 12px;
  text-align: left;
}

.filtersAndExport {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: row;
}

.filters {
  display: flex;
}
.filters > div:not(:first-child) {
  margin-left: 15px;
}

.filtersCount {
  text-align: center;
  line-height: 36px;
}
.filtersCount button {
  /* border: 1px solid #d4d4d4; */
  border: none;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  font-size: 12px;
  background-color: #f1c40f;
  margin-left: 2px;
  color: #564500;
  padding: 0 10px;
}

.filtersName {
  margin-bottom: 5px;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 12px;
}

.filters select {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.pagination button {
  background-color: #f1c40f;
  color: #1a1a1a;
  border: none;
  padding: 8px 12px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination button:hover {
  background-color: #d4ac0d;
}

.pagination button:disabled {
  background-color: #a9a9a9;
  cursor: not-allowed;
}

.export {
  margin-top: 16px;
  margin-bottom: 16px;
}

.export button {
  background-color: #f1c40f;
  color: #564500;
  border: none;
  padding: 8px 12px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.export button:hover {
  background-color: #d4ac0d;
}

@media screen and (max-width: 768px) {
  .tableContainer {
    display: block;
  }

  .tableContainer thead {
    display: none;
  }

  .tableContainer tbody {
    display: grid;
    grid-gap: 16px;
  }

  .tableContainer tbody tr {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #1a1a1a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .tableContainer td {
    padding: 8px 0;
  }

  .tableContainer td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 8px;
  }
}

.promotionsTable {
  margin-top: 20px;
}
